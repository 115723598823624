.img {
    margin-top: 80px;
    width: 60%;
    border-radius: 30px;
}

.heading {
    font-size: 30px;
    font-weight: 600;
    color: #9951c4;
    text-align: left;
}

.subHeading {
    font-size: 30px;
    font-weight: bold;
    text-align: left;
    width: 400px;
    margin-bottom: 20px;
}

.content {
    font-size: 20px;
    text-align: left;
    color: #1f354e;
    width: 80%;
    text-align: justify;
    margin-bottom: 30px;
}

.btn {
    width: 272px;
    height: 64px;
    font-size: 14px;
}

.text {
    margin-left: 50px;
    margin-top: 50px;
}

@media screen and (max-width: 1440px) {
    .img {
        margin-top: 160px;
        width: 70%;
    }
}

@media screen and (max-width: 1200px) {
    .text {
        margin-top: 20px;
    }
    .img {
        width: 70%;
    }
}

@media screen and (max-width: 1100px) {
    .heading {
        font-size: 26px;
    }
    .subHeading {
        font-size: 28px;
        width: 300px;
    }
    .btn {
        height: 50px;
        width: 200px;
    }
    .img {
        width: 70%;
    }
}

@media screen and (max-width: 1024px) {
    .img {
        margin-top: 200px;
        width: 90%;
    }
}

@media screen and (max-width: 950px) {
    .imgDiv {
        margin-top: 100px;
    }
    .img {
        width: 75%;
    }
}

@media screen and (max-width: 768px) {
    .imgDiv {
        margin-top: 0px;
    }
    .img {
        margin-top: 200px;
        width: 90%;
    }
    .heading {
        font-size: 24px;
        line-height: 1.4;
    }
    .subHeading {
        font-size: 26px;
        margin-bottom: 20px;
        width: 100%;
    }
    .content {
        font-size: 18px;
        width: 90%;
        line-height: 1.6; 
    }
    .btn {
        width: 250px;
        height: 55px;
        font-size: 16px; 
        padding: 0 15px; 
    }
    .text {
        margin-left: 20px;
        margin-top: 20px; 
    }
}

@media screen and (max-width: 500px) {
    .img {
        width: 90%;
        margin-top: 0px;
    }
    .heading {
        font-size: 23px;
    }
    .subHeading {
        font-size: 22px;
        margin-bottom: 20px;
        width: 100%;
    }
    .content {
        font-size: 18px;
        margin-bottom: 23px;
        width: 95%;
    }
    .btn {
        width: 180px;
        height: 45px;
    }
    .text {
        margin-left: 10px;
        margin-top: 15px;
    }
}

@media screen and (max-width: 355px) {
    .subHeading {
        width: 100%;
        font-size: 20px;
    }
    .content {
        font-size: 16px;
        width: 100%;
    }
    .btn {
        width: 160px;
        height: 40px;
    }
}
