.contain {
  margin-top: 130px;
  margin-bottom: 130px;
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.heading {
  font-size: 30px;
  font-weight: 600;
  color: #9951c4;
}

.subHeading {
  font-size: 34px;
  font-weight: bold;
  color: #222a41;
  width: 645px;
}

@media screen and (max-width: 1200px) {
  .contain {
    margin-top: 80px;
    margin-bottom: 80px;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .heading {
    font-size: 34px;
  }
  .subHeading {
    font-size: 38px;
    width: auto;
  }
}

@media screen and (max-width: 770px) {
  .subHeading {
    font-size: 30px;
    width: auto;
  }
  .heading {
    font-size: 28px;
  }
  .contain {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 600px) {
  .subHeading {
    font-size: 28px;
    width: auto;
  }
  .heading {
    font-size: 26px;
  }
  .contain {
    margin-top: 40px;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 430px) {
  .subHeading {
    font-size: 26px;
    width: 100%;
  }
  .heading {
    font-size: 24px;
  }
  .contain {
    margin-top: 30px;
  }
}

@media screen and (max-width: 380px) {
  .contain {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .heading {
    font-size: 22px;
  }
  .subHeading {
    font-size: 22px;
  }
}
