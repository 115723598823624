.contain {
  margin-top: 83px;
  margin-bottom: 174px;
}

.cont {
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 20px;
}

.heading {
  font-size: 35px;
  color: #9951c4;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}

.subHeading {
  font-size: 34px;
  font-weight: bold;
  color: #222a41;
  width: 800px;
  margin: 0 auto 50px;
  text-align: center;
  margin-top: -25px;
}

.box {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 30px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f9f9f9;
}

.box:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.25);
}

.img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  object-fit: cover;
  margin: 0 auto 15px auto;
}

.cardHeading {
  font-size: 25px;
  color: #333;
  font-weight: bold;
  margin-top: 15px;
}

.cardText {
  font-size: 19px;
  color: #4b4949;
  margin-top: 10px;
  flex-grow: 1;
}
.heading_content {
  font-size: 22px;
  font-weight: 400;
  color: #444554;
  margin-bottom: 30px;
}

.logo_head {
  font-size: 30px;
  font-weight: 600;
  color: #212121;
  margin-top: 20px;
}

.img1 {
  margin-top: 50px;
}

/* Media Queries */
@media screen and (max-width: 1200px) {
  .cont {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 770px) {
  .subHeading {
    font-size: 25px;
    width: auto;
  }
  .heading {
    font-size: 30px;
  }
}

@media screen and (max-width: 430px) {
  .subHeading {
    font-size: 22px;
  }
  .heading {
    font-size: 37px;
  }
  .contain {
    margin-top: 80px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 380px) {
  .subHeading {
    font-size: 22px;
  }
  .heading {
    font-size: 37px;
  }
  .cont {
    padding-left: 10px;
    padding-right: 10px;
  }
}
