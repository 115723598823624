@import url("https://fonts.googleapis.com/css2?family=Newsreader:wght@400;600&display=swap");

.nav_text {
  font-size: 19px;
  color: #111111 !important;
  width: 120px;
  font-family: "Newsreader", serif;
}

.nav_text:hover {
  padding-top: 6px;
  font-size: 18px;
  color: #8a2be2 !important;
  font-weight: bold;
}

.active {
  padding-top: 6px !important;
  font-size: 18px;
  color: #8a2be2 !important;
  font-weight: bold;
}

.drop {
  width: 120px;
}

.drop:hover .nav_text {
  color: #8a2be2 !important;
  font-weight: bold;
  cursor: pointer;
  font-size: 18px;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.logoImage {
  height: 50px;
  margin-right: 10px;
  vertical-align: middle;
  cursor: pointer;
}

.logo {
  color: #b5208c !important;
  font-weight: 600;
  font-size: 30px !important;
  text-align: start;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  font-family: "Newsreader", serif;
}

.subLogo {
  font-size: 19px;
  color: #111111;
  font-weight: normal;
  text-align: center;
  text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.2);
  font-family: "Poppins", serif;
  margin-top: -10px;
}


@media screen and (max-width: 991px) {
  .subLogo {
    font-size: 16px; 
  }
}

@media screen and (max-width: 768px) {
  .subLogo {
    font-size: 14px; 
  }
}

@media screen and (max-width: 600px) {
  .logo {
    font-size: 25px !important;
  }
  .subLogo {
    font-size: 14px;
  }
}

.dropdownText {
  text-decoration: none;
  color: #111111;
  font-family: "Newsreader", serif;
}

.dropdownItem:hover .dropdownText {
  color: #8a2be2;
}

.drop a:hover {
  background-color: white;
}

@media screen and (max-width: 991px) {
  .dropdownItem {
    text-align: center !important;
  }
  .nav_text {
    width: auto;
    padding-top: 0px;
  }
  .drop {
    width: auto;
  }
  .logo {
    font-size: 40px !important;
  }
}
