.contain {
    padding-top: 146px;
    padding-bottom: 145px;
}

.mainHead {
    font-size: 60px;
    font-weight: bold;
    color: #222a41;
    margin-bottom: 10px;
}

.mainContent {
    font-size: 23px;
    font-weight: 600;
    color: #4f4c86;
    padding-bottom: 30px;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
}

.box {
    background-color: rgba(79, 76, 134, 0.1);
    border: solid 2px rgba(220, 216, 226, 0.5);
    border-radius: 10px;
    padding: 20px 27px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 350px;
    overflow: hidden;
    align-items: center;
}

.box img {
    max-width: 30%;
    height: auto;
    object-fit: contain;
    margin-bottom: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.head {
    font-size: 22px;
    font-weight: bold;
    color: #070707;
    margin-top: 5px;
}

.content {
    font-size: 21px;
    color: #6f7473;
    line-height: 1.3;
    max-width: 900px;
    margin-top: 0;
}

@media screen and (max-width: 992px) {
    .mainHead {
        font-size: 40px;
    }

    .mainContent {
        font-size: 20px;
        padding-bottom: 70px;
    }

    .contain {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .box {
        padding: 20px 10px;
        height: 350px;
    }

    .head {
        margin-bottom: 15px;
        margin-top: 15px;
    }

    .content {
        font-size: 18px;
    }
}

@media screen and (max-width: 768px) {
    .box {
        margin-bottom: 10px;
        height: 350px;
    }
}

@media screen and (max-width: 650px) {
    .contain {
        padding-top: 50px;
    }

    .mainHead {
        font-size: 30px;
    }

    .mainContent {
        font-size: 18px;
    }

    .head {
        font-size: 22px;
        margin-top: -40px;
    }

    .content {
        font-size: 17px;
        margin-top: -40px;
    }

    .box {
        height: 350px;
    }
}
