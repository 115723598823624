.container {
    margin-top: 150px;
    margin-bottom: 150px;
}

.box {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-top: 7px;
    margin-bottom: 60px;
    padding-top: 19px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    border-radius: 15px;
    cursor: pointer;
    user-select: none;
}

.head {
    font-size: 34px;
    font-weight: 600;
    color: #9951c4;
}

.subHead {
    font-size: 46px;
    font-weight: bold;
    color: #222a41;
    margin-bottom: 60px;
}

.img {
    width: 60px;
    height: auto;
    border-radius: 50px;
}

.name {
    font-size: 24px;
    text-align: left;
    margin-bottom: 0px;
    color: #222a41;
    font-weight: bold;
}

.profession {
    font-size: 20px;
    text-align: left;
    color: #222a41;
}

.msg {
    text-align: left;
    color: #222a41;
    font-size: 18px;
    padding-top: 5px;
}

.filled-star {
    color: #FFD700;
}

.empty-star {
    color: #ccc;
}

.stars {
    display: flex;
    gap: 2px;
}

@media screen and (max-width: 1200px) {
    .head {
        font-size: 30px;
    }
    .subHead {
        font-size: 44px;
    }
    .name {
        font-size: 23px;
    }
    .profession {
        font-size: 17px;
    }
    .msg {
        font-size: 16px;
    }
    .img {
        width: 55px;
    }
}

@media screen and (max-width: 810px) {
    .head {
        font-size: 26px;
    }
    .subHead {
        font-size: 36px;
        margin-bottom: 60px;
    }
    .container {
        margin-bottom: 100px;
        margin-top: 100px;
    }
    .msg {
        padding-top: 10px;
    }
}

@media screen and (max-width: 575px) {
    .name {
        text-align: center;
        margin-top: 10px;
    }
    .profession {
        text-align: center;
    }
    .msg {
        text-align: justify;
    }
}
