.react-multi-carousel-dot > button {
    background-color: #6f6d71 !important; 
    border: 0px !important;
    width: 10px !important;
    height: 10px !important;
}

.react-multi-carousel-dot--active > button {
    background-color: #9951c4 !important; 
}
