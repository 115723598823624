.contain {
  margin-top: 125px;
  margin-bottom: 125px;
}

.heading {
  font-size: 52px;
  font-weight: 700;
  color: #9951c4;
  margin-bottom: 20px;
}

.heading_content {
  font-size: 22px;
  font-weight: 400;
  color: #444554;
}

.logo_head {
  font-size: 30px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 21px;
}


.contentImg {
  width: 80%;
  border-radius: 10px;
  transition: transform 0.3s;
}

.contentImg:hover {
  transform: scale(1.05);
}

.subHead {
  margin-top: 60px;
  text-align: left;
}

.justify {
  text-align: justify;
}

@media screen and (max-width: 1200px) {
  .contentImg {
    width: 90%;
  }

  .heading {
    font-size: 48px;
  }

  .heading_content {
    font-size: 22px;
  }

  .logo_head {
    font-size: 26px;
  }

  .subHead {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 992px) {
  .contentImg {
    width: 100%;
  }

  .heading {
    font-size: 36px;
  }

  .heading_content {
    font-size: 20px;
  }

  .logo_head {
    font-size: 24px;
  }

  .subHead {
    margin-top: 0px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {
  .heading {
    font-size: 32px;
  }

  .heading_content {
    font-size: 18px;
  }

  .logo_head {
    font-size: 20px;
  }

  .subHead {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 576px) {
  .heading {
    font-size: 29px;
  }

  .heading_content {
    font-size: 19px;
  }

  .logo_head {
    font-size: 19px;
  }
}
