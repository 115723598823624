.contain {
  margin-top: 83px;
  margin-bottom: 174px;
}

.cont {
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 20px;
}

/* Headings */
.heading {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}

.subHeading {
  font-size: 34px;
  font-weight: bold;
  color: #222a41;
  width: 645px;
  margin: 0 auto;
  text-align: center;
}

.box {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 380px; 
  display: flex;
  flex-direction: column;
  justify-content: center; 
  margin: 20px 0; 
}

.box:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}


.img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  object-fit: cover;
  margin: 0 auto 15px auto; 
}

.cardHeading {
  font-size: 23px;
  color: #222a41;
  font-weight: bold;
  margin-top: 15px;
}

.cardText {
  font-size: 19px;
  color: #31353b;
  margin-top: 10px;
  flex-grow: 1; 
}

/* Media Queries */
@media screen and (max-width: 1200px) {
  .cont {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 770px) {
  .subHeading {
    font-size: 25px;
    width: auto;
  }
  .heading {
    font-size: 20px;
  }
}

@media screen and (max-width: 430px) {
  .subHeading {
    font-size: 20px;
  }
  .heading {
    font-size: 17px;
  }
  .contain {
    margin-top: 80px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 380px) {
  .cont {
    padding-left: 10px;
    padding-right: 10px;
  }
}
