.head{
    font-size: 30px;
    font-weight: 600;
    color: #9951c4;
    text-align: left;
    margin-bottom: 20px;
}
.heading{
    font-size: 50px;
    color: #222a41;
    font-weight: 600;
    text-align: left;
    margin-bottom: 34px;
    width: 90%;
    line-height: 1.3;
}
.content{
    font-size: 19px;
    color: #222a41;
    text-align: left;
    margin-bottom: 36px;
    text-align: justify;
}
.btn{
    width: 270px;
    height: 64px;
}
.contain{
    margin-top: 143px; 
    margin-bottom: 143px;
}

.row{
    display: flex;
    flex-wrap: wrap;
    padding: 0 8px;
    margin-top: 65px;
    justify-content: right;

}
.column {
    flex: 25%;
    max-width: 45%;
    padding: 0 8px;
}
.column img {
    margin-top: 70px;
    vertical-align: middle;
    width: 100%;
    border-radius: 10px; 
}

.col1{
    margin-top: 30px;
}

@media screen and (max-width: 1200px){
    .heading{
        font-size: 40px;
    }
    .row{
        padding: 0px 6px;
        margin-top: 85px;
    }
    .column{
        padding: 0px 6px;
    }
    .column img{
        margin-top: 11px;
    }
    .col1{
        margin-top: 70px;
    }
}
@media screen and (max-width: 991px){
    .head{
        font-size: 25px;
    }
    .heading{
        width: 100%;
    }
    .row{
        margin-top: 110px;
    }
    .col1{
        margin-top: 60px;
    }
}
@media screen and (max-width: 768px){
    .row{
        margin-top: 50px;
        justify-content: center;
    }
    .col1{
        margin-top: 80px;
    }
}

@media screen and (max-width: 500px){
    .head{
        font-size: 20px;
    }
    .heading{
        font-size: 34px;
    }
    .btn{
        width: 200px;
        height: 54px;
    }

    .row{
        padding: 0px 4px;
        margin-top: 20px;
    }
    .column{
        padding: 0px 4px;
    }
    .column img{
        margin-top: 8px;
    }
}

@media screen and (max-width: 428px){
    .col1{
        margin-top: 60px;
    }
    .contain{
        margin-bottom: 80px;
        margin-top: 80px;
    }
}
@media screen and (max-width: 340px){
    .col1{
        margin-top: 40px;
    }

}