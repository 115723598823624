.contain {
  margin-top: 68px;
  margin-bottom: 68px;
}

.heading {
  font-size: 52px;
  color: #222a41;
  font-weight: bold;
  width: 600px;
  margin-bottom: 20px;
}

.head {
  font-size: 26px;
  color: #222a41;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 16px;
}

.content {
  font-size: 24px;
  color: #232323;
  opacity: 0.6;
  margin-bottom: 10px;
}

.email {
  font-size: 40px;
  color: #222a41;
  font-weight: bold;
  margin-top: 43px;
  margin-bottom: 43px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; 
}

.popupContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.closeBtn {
  background-color: #007bff; 
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.label {
  font-size: 20px;
  color: #232323;
  margin-left: 10px;
}

.msg {
  height: 183px !important;
  padding-top: 15px;
  margin-bottom: 20px;
}

.input {
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 6px;
  border: solid 1px #e8e8e8;
  height: 60px;
  margin: 12px 0px 20px;
  padding-left: 15px;
}

.input:focus {
  outline: none;
  border: solid 1px #afacac;
}

.btn {
  font-size: 20px;
  height: 60px;
  width: 160px;
  border-radius: 5px !important;
  margin-top: 35px;
}

@media screen and (max-width: 1200px) {
  .heading {
    font-size: 40px;
  }
  .head {
    font-size: 22px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .content {
    font-size: 22px;
  }
  .email {
    font-size: 32px;
  }
}

@media screen and (max-width: 768px) {
  .heading {
    width: 100%;
  }
  .head {
    margin-top: 20px;
  }
}

@media screen and (max-width: 600px) {
  .heading {
    font-size: 30px;
  }
  .head {
    margin-top: 15px;
    font-size: 20px;
  }
  .content {
    font-size: 20px;
  }
  .label {
    font-size: 18px;
  }
  .input {
    height: 40px;
  }
}

.link {
  color: #222a41;
}

.link:hover {
  text-decoration: underline;
}

.formContainer {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
