.heading {
    font-size: 42px;
    font-weight: 600;
    color: #9951c4;
    margin-top: 77px;
    margin-bottom: 0px;
}

.subHeading {
    font-size: 35px;
    font-weight: 600;
    color: #2e2d26;
}

.content {
    font-size: 20px;
    color: #6f7473;
    margin-top: 14px;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 70px;
}

.contain {
    padding-left: 100px;
}

.about {
    font-size: 64px;
    font-weight: bold;
    color: #0f0a18;
    text-align: left;
    margin-bottom: -14px;
    margin-top: 100px;
}

.imgContain {
    margin-left: -90px;
}

.img1 {
    margin-top: 90px;
    margin-left: 50px;
    width: 60%;
    height: auto; 
    border-radius: 30px;
}

.img2 {
    text-align: left;
    padding-left: 100px;
    width: 100%;
    height: auto;
    margin-bottom: 82px;
}

.creativity {
    font-size: 38px;
    font-weight: 600;
    color: #4f4c86;
    text-align: left;
}

.contn {
    font-size: 20px;
    color: #6f7473;
    text-align: left;
}

@media screen and (max-width: 1200px) {
    .contain {
        padding-left: 40px;
    }
    .img2 {
        padding-left: 40px;
    }
    .imgContain {
        margin-left: -60px;
    }
}

@media screen and (max-width: 1024px) {
    .contain {
        padding-left: 40px;
    }
    .img1{
        margin-top: 200px;
    }
    .img2 {
        padding-left: 40px;
    }
    .imgContain {
        margin-left: -60px;
    }
}

@media screen and (max-width: 992px) {
    .heading {
        font-size: 36px;
        margin-top: 60px;
    }
    .subHeading {
        font-size: 40px;
        margin-top: 30px;
    }
    .contain {
        padding-left: 0px;
    }
    .img2 {
        padding-left: 0px;
        margin-bottom: 60px;
    }
    .imgContain {
        margin-left: -30px;
    }
    .about {
        font-size: 52px;
    }
    .creativity {
        font-size: 34px;
    }
}

@media screen and (max-width: 768px) {
    .content {
        width: 100%;
    }
    .about {
        margin-top: 80px;
    }
    .imgContain {
        margin-left: 0px;
    }
    .heading {
        font-size: 30px;
    }
    .subHeading {
        font-size: 28px;
    }
    .img1{
        margin-top: 200px;
        width: 80%;
    }
}

@media screen and (max-width: 650px) {
    .heading {
        font-size: 28px;
        margin-top: 40px;
    }
    .subHeading {
        font-size: 32px;
        margin-top: 20px;
    }
    .img2 {
        margin-bottom: 40px;
    }
    .about {
        font-size: 42px;
        margin-top: 60px;
    }
    .creativity {
        font-size: 28px;
    }
}

@media screen and (max-width: 425px) {
    .about {
        margin-top: 80px;
        margin-bottom: 10px;
    }
    .content {
        width: 100%;
        font-size: 18px;
    }
    .about {
        font-size: 36px;
        margin-top: 40px;
    }
    .creativity {
        font-size: 26px;
    }
    .img1 {
        width: 80%;
        margin-left: 20px;
        margin-top: 0; 
    }
    .img2 {
        padding-left: 0px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 375px) {
    .img1 {
        width: 90%;
        margin-left: 0;
        margin-top: 0; 
    }
    .heading {
        font-size: 22px; 
        margin-bottom: 20px;
    }
    .subHeading {
        font-size: 26px; 
    }
}

