.contain {
  padding-bottom: 100px;
}

.row {
  margin-top: 100px !important;
}

.head {
  font-size: 36px;
  font-weight: bold;
  color: #4f4c86;
  text-align: left;
  margin-top: 50px;
  margin-left: 20px;
  transition: color 0.3s;
}

.head:hover {
  color: #2e2d26;
}

.content {
  font-size: 20px;
  color: #0f0a18;
  opacity: 0.7;
  text-align: left;
  margin-left: 20px;
}

.img {
  width: 60%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
}

.img1 {
  width: 60%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
}

.head1 {
  font-size: 36px;
  font-weight: bold;
  color: #4f4c86;
  text-align: left;
  margin-top: 50px;
  margin-left: 0;
  transition: color 0.3s;
}

.content1 {
  font-size: 20px;
  color: #0f0a18;
  opacity: 0.7;
  text-align: left;
  margin-right: 100px;
}

@media screen and (max-width: 1024px) {
  .head {
    margin-left: 0;
    margin-top: 70px;
    font-size: 32px;
  }

  .content {
    margin-left: 0;
    font-size: 18px;
  }

  .img {
    margin-top: 100px;
    width: 65%;
    margin-left: auto;
    margin-right: auto;
  }

  .img1 {
    margin-top: 100px;
    width: 65%;
    margin-left: auto;
    margin-right: auto;
  }

  .head1 {
    margin-top: 70px;
    font-size: 32px;
    margin-left: 0;
  }

  .content1 {
    margin-right: 0;
    font-size: 18px;
  }

  .row {
    margin-top: 70px !important;
  }

  .contain {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 768px) {
  .img,
  .img1 {
    width: 80%;
    margin-left: 0;
  }

  .head,
  .head1 {
    font-size: 26px;
    margin-top: 30px;
    margin-left: 0;
  }

  .content,
  .content1 {
    font-size: 16px;
    margin-left: 0;
  }

  .row {
    margin-top: 50px !important;
  }

  .contain {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 480px) {
  .head,
  .head1 {
    font-size: 22px;
    margin-top: 20px;
    margin-left: 0;
  }

  .content,
  .content1 {
    font-size: 14px;
  }

  .img,
  .img1 {
    width: 90%;
    margin-left: 0;
    margin-top: 30px;
  }

  .row {
    margin-top: 30px !important;
  }

  .contain {
    padding-bottom: 40px;
  }
}
