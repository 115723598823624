.img1 {
  width: 60%;
  padding-top: 20px;
}

.img2 {
  width: 50%;
  height: auto;
  margin-top: 70px;
}

.img3 {
  width: 60%;
  height: auto;
  max-width: 600px;
  margin: 0 auto;
}

.img4 {
  width: 70%;
  margin-top: 1rem;
}

.img1:hover,
.img2:hover,
.img3:hover,
.img4:hover {
  transform: scale(1.05);
}

.topContain {
  padding: 50px 50px 20px 30px;
  text-align: center;
}

.topContain1 {
  padding: 50px 50px 20px 30px;
  text-align: center;
  margin-top: 80px;
}

.headTop {
  font-size: 40px;
  color: #212121;
  font-weight: bolder;
  line-height: 1.2;
  margin-bottom: 0;
}

.identityTop {
  font-size: 30px;
  color: #111111;
  font-weight: bold;
  margin: 15px 0;
}

.content {
  font-size: 22px;
  color: #111;
  line-height: 1.3;
}

.contain {
  margin: 150px 0 !important;
  margin-bottom: 5px;
}

.heading {
  font-size: 35px;
  font-weight: bold;
  text-align: center;
}

.text {
  font-size: 22px;
  color: #444554;
  margin: 0 auto;
  line-height: 1.3;
}

.bottomContain {
  padding-left: 80px !important;
}

.heading1 {
  font-size: 35px;
  font-weight: bold;
  margin-top: -20px;
  text-align: center;
}

.text1 {
  font-size: 22px;
  color: #444554;
  margin: 0 auto;
  line-height: 1.3;
}

/* Media Queries for Responsive Design */
@media screen and (max-width: 1280px) {
  .topContain {
    margin-top: 40px;
  }

  .img1,
  .img3 {
    width: 70%;
  }

  .img2 {
    width: 60%;
  }
}

@media screen and (max-width: 1200px) {
  .topContain {
    margin-top: 30px;
  }

  .img1 {
    width: 75%;
  }

  .img2 {
    width: 65%;
  }
}

@media screen and (max-width: 1155px) {
  .topContain {
    margin-top: 20px;
  }

  .img1 {
    width: 80%;
  }
}

@media screen and (max-width: 1100px) {
  .topContain {
    padding-left: 90px;
  }

  .headTop {
    font-size: 35px;
  }

  .identityTop {
    font-size: 27px;
  }

  .content {
    font-size: 21px;
  }

  .img1,
  .img2,
  .img3,
  .img4 {
    width: 85%;
  }
}

@media screen and (max-width: 1030px) {
  .img1 {
    margin-top: 10px;
    width: 90%;
  }

  .img2 {
    width: 90%;
  }
}

@media screen and (max-width: 992px) {
  .topContain {
    padding-left: 70px;
  }

  .bottomContain {
    padding-left: 50px !important;
  }

  .text1 {
    width: 100%;
  }

  .img1 {
    margin-top: 20px;
    width: 90%;
  }

  .img2 {
    padding-top: 60px;
    width: 90%;
  }
}

@media screen and (max-width: 962px) {
  .img1 {
    margin-top: 40px;
    width: 90%;
  }
}

@media screen and (max-width: 900px) {
  .img1 {
    margin-top: 40px;
    width: 90%;
  }
}

@media screen and (max-width: 767px) {
  .topContain {
    margin-top: 0;
    padding: 20px 10px;
  }

  .img2 {
    padding-top: 0;
    width: 100%;
  }

  .text {
    width: 100%;
  }

  .img1 {
    margin-top: -50px !important;
    width: 100%;
  }

  .contain {
    margin: 50px 0 !important;
  }

  .heading {
    margin-top: 20px;
  }

  .heading1 {
    margin-top: 20px;
  }

  .middleContain {
    padding-left: 20px;
  }
}

@media screen and (max-width: 850px) {
  .topContain {
    padding: 50px 30px;
  }

  .middleContain {
    padding-left: 20px !important;
  }

  .img1 {
    margin-top: 50px;
    width: 100%;
  }
}

/* Additional media queries for better responsiveness */
@media screen and (max-width: 600px) {
  .headTop {
    font-size: 28px;
  }

  .identityTop {
    font-size: 24px;
  }

  .content,
  .text,
  .text1 {
    font-size: 18px;
  }
  .topContain1 {
    text-align: center;
    margin-top: -80px;
  }

  .img1,
  .img2,
  .img3,
  .img4 {
    width: 90%;
    margin: 0 auto;
  }
}
