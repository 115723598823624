/* .logo {
    color: #b5208c !important;
    font-weight: 600;
    font-size: 32px !important;
    text-align: start;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
} */

.logotext {
    color: #fefefe;
    text-align: left;
    font-weight: 500;
    font-size: 30px !important;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    margin-bottom: 0px;
    margin-top: -20px;
    margin-bottom: 50px;
}

.text {
    color: #fefefe;
    text-align: left;
    font-size: 18px;
    margin-bottom: 0px;
}

.social_logo {
    text-align: left;
}

.img {
    margin-right: 14px;
    margin-bottom: 40px;
    cursor: pointer;
    margin-top: -40px;
}

  
.img:hover {
    transform: scale(1.1);
}

.head {
    color: #fefefe;
    font-size: 24px;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 28px;
    font-weight: 500;
}

.bottom {
    color: #fefefe;
    font-size: 19px;
    margin-bottom: 0px;
    height: 49px;
    padding-top: 12px;
}

@media screen and (max-width: 992px) {
    .text {
        width: 230px;
    }
    .logo {
        font-size: 36px;
    }
}

@media screen and (max-width: 600px) {
    .logo {
        font-size: 30px;
    }
    .head {
        font-size: 18px;
    }
    .text {
        font-size: 16px;
    }
    .bottom {
        font-size: 16px;
        height: 45px;
    }
}

@media screen and (max-width: 575px) {
    .contain {
        padding-left: 30% !important;
        margin-bottom: 25px;
    }
    .head {
        margin-bottom: 10px;
    }
    .img {
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 455px) {
    .contain {
        padding-left: 20% !important;
    }

    .logotext {
        text-align: center;
    }
}

@media screen and (max-width: 350px) {
    .contain {
        padding-left: 10% !important;
    }
    .text {
        width: 100%;
    }
}
