.imgbox {
  height: 94vh;
  position: relative;
  overflow: hidden;
  width: 100%;
  background-size: cover;
  background-position: center;
  transition: background-image 1s ease-in-out;
}

.center {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding: 20px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 30px; 
  display: inline-block;
}

.center::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: -1;
  border-radius: 30px; 
}

.text {
  font-size: 67px;
  color: #ffffff;
  font-weight: 500;
  white-space: nowrap;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
}

.subText {
  font-size: 22px;
  color: white;
  font-weight: 500;
  margin-top: -20px;
  max-width: 400px;
  line-height: 1.2;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  border-radius: 5px;
}

.carouselControl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
}

.carouselControl:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.carousel-control-prev {
  left: 15px;
}

.carousel-control-next {
  right: 15px;
}

@media screen and (max-width: 1266px) {
  .imgbox {
    height: 70vh;
  }
  .text {
    font-size: 57px;
    font-weight: 500;
  }
  .subText {
    font-size: 18px;
  }
}

@media screen and (max-width: 1090px) {
  .imgbox {
    height: 60vh;
  }
  .text {
    font-size: 45px;
    font-weight: 500;
  }
  .subText {
    font-size: 16px;
  }
}

@media screen and (max-width: 921px) {
  .imgbox {
    height: 50vh;
  }
  .text {
    font-size: 37px;
    font-weight: 700;
  }
  .subText {
    font-size: 14px;
  }
}

@media screen and (max-width: 715px) {
  .imgbox {
    height: 40vh;
  }
  .text {
    font-size: 27px;
    font-weight: 700;
  }
  .subText {
    font-size: 19px;
  }
}

@media screen and (max-width: 524px) {
  .imgbox {
    height: 35vh;
  }
  .text {
    width: 100%;
    font-weight: 700;
  }
  .subText {
    font-size: 13px;
  }
}

@media screen and (max-width: 396px) {
  .imgbox {
    height: 30vh;
  }
  .text {
    font-size: 25px;
    font-weight: 700;
  }
  .subText {
    font-size: 13px;
  }
}
